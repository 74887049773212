@use '../../master.scss';

.main-header {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  background: #fff;
  min-width: 100vw;
  width: 100%;
  // @media only screen and (max-width: 1025px) {
  //   padding: 12px 0;
  // }
  z-index: 1;
  margin: 0px !important;

  .headerWrapper {
    padding: 15px 15px 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1560px;
    margin: 0px auto;
  }
}

.header-logo {
  width: 60px;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.logo-title {
  display: flex;
  align-items: center;
  gap: 10px;
}
.logo-title a {
  margin: 0;
  color: #00aca8;
  font-weight: 700;
  font-size: 20px;
  @media only screen and (max-width: 960px) {
    font-size: 14px;
  }
}
.logo-title a:hover {
  color: #00aca8;
  text-decoration: underline;
}
.profile-icon {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 20px;
  background: #00aca8;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.profile-icon p {
  margin: 0;
  color: #fff;
  font-size: 18px;
}
