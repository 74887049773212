.sidebar-cont {
  background: #fff;
  width: 22%;
  // height: 100vh !important;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 100%;
    flex-direction: row;
    overflow-x: scroll;
  }
}
.sidebar-cont h5 {
  color: #404041;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  // @media only screen and (max-width: 960px) {
  //   display: none;
  // }
}

hr {
  background-color: #4ca9a7;
  width: 100%;
  border: none;
  opacity: 1;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.sidebar-options {
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  //padding: 15px 5px;
  // border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  cursor: pointer;
  user-select: none;
  @media only screen and (max-width: 960px) {
    width: 50%;
    flex-direction: column;
    justify-content: center;
  }
  .icon-wrapper {
    display: none;
    // width: 0%;
    // display: flex;
    // justify-content: space-between;
  }
}
.sidebar-options:focus {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e699;
  border-bottom: 1px solid #b3b3b3;
  padding: 15px 5px;
  // height: 50%;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    width: 50%;
    flex-direction: column;
    justify-content: center;
  }
  .icon-wrapper {
    width: 90px;
    display: flex;
    justify-content: space-between;
  }
}
.selected-sidebar-options {
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e699;
  border-bottom: 1px solid #b3b3b3;
  //padding: 15px 5px;
  // height: 50%;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    width: 50%;
    flex-direction: column;
    justify-content: center;
  }
  // .icon-wrapper {
  //   width: 60px;
  //   display: flex;
  //   justify-content: space-between;
  // }
}

.sidebar-options:focus p {
  // width: max-content;
  margin: 0;
  color: #00aca8;
  font-size: 14px;
}
.selected-sidebar-options p {
  margin: 0;
  color: #00aca8;
  font-size: 14px;
}
.circle-edit-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 60%;
    justify-content: center;
    align-items: center;
  }
  .ant-input {
    outline: none !important;
    background: transparent !important;
    border: 0px;
    border-bottom: 1px solid #00aca8;
    width: max-content !important;
  }
}
.sidebar-options p {
  margin: 0;
  // width: 100%;
  font-size: 14px;
}
.sidebar-logo {
  width: 30px;
}
.sidebar-circle-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.sidebar-circle-inner-wrapper-mob {
  width: 100%;
  height: max-content;
  @media only screen and (min-width: 960px) {
    display: none;
  }
  .circle-select-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    .select-search {
      width: 70%;
    }
  }
}

.sidebar-circle-inner-wrapper {
  overflow-y: scroll;

  height: 60vh;
  width: 100%;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.sidebar-circle-inner-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.sidebar-circle-inner-wrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.sidebar-circle-inner-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.add-circle-wrapper {
  form {
    width: 100% !important;
    text-align: center;
    min-height: 170px;
    .add-circle-input {
      margin-top: 5px;
      border: 1px solid #00aca8;
      border-radius: 20px;
      margin-bottom: 10px;
      max-width: 400px !important;
      width: 100% !important;
    }
    .add-circle-btn {
      background-color: #00aca8 !important;
      color: #fff !important;
      width: 100% !important;
      max-width: 200px;
      // padding: 10px 0 !important;
      border-radius: 10px !important;
    }
  }
}

.circle-name-div {
  flex: 1;
  width: 200px;
  padding: 15px 5px;
}

.circle-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: left;
  width: 130px;
  font-size: 12px;
  @media only screen and (max-width: 1100px) {
    width: 100px;
  }
}
