.inviteScreen {
  padding: 90px 15px 20px 15px;
  min-height: 100vh;
  // margin-top: 80px;

  .share-input {
    width: 100%;
  }
  .share-form {
    width: 100% !important;
    max-width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5% !important;
    @media only screen and (max-width: 960px) {
      width: 100% !important;
    }

    p {
      font-size: 14px;
      padding: 0px 30px;
      color: grey;
      font-weight: bold;
    }

    .share-input {
      padding: 12px 16px;
      text-align: center !important;
      &:focus {
        text-align: left !important;
      }
    }
  }
  .share-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 960px) {
      width: 100% !important;
    }
  }
  .share-btn {
    margin-top: 10px !important;
    background-color: #00aca8;
    border: none;
    border-radius: 10px;
    width: 120px;
    color: #fff;
  }
}
