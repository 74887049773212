@use '../../master.scss';

.certer-modal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-title {
    color: master.$theme-color;
  }

  .title {
    font-size: 14px;
    color: #000;
    margin-bottom: 2px;
    display: block;
  }
}

.signup-btn-center-modal {
  width: 140px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  margin: 0 auto;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  background: #00aca8;
  cursor: pointer;
  p {
    color: white;
    height: 8px;
  }
}

.modal-wrapper-div {
  width: 428px;
  min-height: 260px;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    width: 300px;
    min-height: 300px;
    border-radius: 25px;
    margin: 0 auto;
    display: flex;
    // overflow: auto !important;
  }
}

.iconCrow-div {
  justify-content: center;
  align-items: center;
  display: flex;
}
.modal-title-div {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  p {
    font-weight: 700;
    font-size: 22px;
  }
}
.modal-title {
  font-weight: 'bold';
  font-size: 22px;
}

.modal-body-div {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  p {
    color: #00aca8;
  }
}

.modal-confirmation-des {
  font-size: 13px;
  margin-top: 30px;
}
