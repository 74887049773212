.new-cont-project-detail-view {
  padding: 90px 15px 20px 15px;
  justify-content: center;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  align-items: center;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    padding: 90px 3px 20px 3px;
  }
  @media only screen and (max-width: 780px) {
    flex-direction: column;
    padding: 70px 0px 20px 0px;
  }
}
.new-form {
  h3 {
    margin-bottom: 0px !important;
  }
  p {
    margin-bottom: 0px !important;
  }
}
.alias-input-cont {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 15px;
  border-bottom: 1px solid #4ca9a7;
  padding: 15px 0 25px 0;
  margin-bottom: 15px;
}
.alias-input {
  width: 50% !important;
  @media only screen and (max-width: 450px) {
    width: 100% !important;
  }
}
.alias-btn {
  margin: 0 !important;
  background-color: #4ca9a7 !important;
  color: #fff !important;
  border-radius: 8px !important;
  width: 150px;
  min-height: 40px !important;
  // width: 15% !important;

  @media only screen and (max-width: 450px) {
    width: 100vw !important;
  }
}
.view-details-box {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfd7ca;
  border-radius: 15px;
  width: 100%;
}
.view-details-box p {
  color: #3e3f3a;
  font-weight: 500;
  font-size: 15px;
  margin: 0;
}
.view-details-status-true {
  color: green;
  margin: 0;
}
.view-details-status-false {
  color: red;
  margin: 0;
}
