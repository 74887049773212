.new-cont-create-draw {
  padding: 90px 15px 20px 15px;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;

  background-size: cover;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 780px) {
    padding: 30px 0px;
    justify-content: flex-start;
  }
}
.new-form {
  width: 70%;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}
.create-button-drawing {
  background-color: #00aca8 !important;
  color: #fff !important;
  // min-width: 30px;
  padding: 0 40px !important;
  border-radius: 10px !important;

  @media only screen and (max-width: 780px) {
    background-color: #697689 !important;
    width: 100%;
  }
}
.new-form {
  .draw-head {
    font-size: 30px !important;

    text-align: center;
    color: #fff;
    font-size: 20px;
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
  .draw-para {
    font-size: 20px !important;
    text-align: center;
    color: #fff;
    font-size: 30px;
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
}

.form-create-drawing {
  min-height: 55vh;
  margin-top: 30px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  width: 97% !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  padding: 15px !important;
  @media only screen and (max-width: 960px) {
    width: 100% !important;
    min-height: 80vh;
  }
  @media only screen and (max-width: 780px) {
    width: 100% !important;
    min-height: 80vh;
    justify-content: space-between !important;
  }
  .create-input {
    border: 2px solid #00aca8 !important;
    border-radius: 25px !important;
    min-height: 40px !important;
    padding: 0 25px;
    background-color: #f2f2f2 !important;
    width: 100% !important;
    @media only screen and (max-width: 780px) {
      border-radius: 10px !important;
    }
  }

  .create-input:focus::placeholder {
    color: transparent !important;
  }
  .create-input::placeholder {
    color: #3e3f3a !important;
  }
}
.form-side {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 15px;
  justify-content: flex-start;
  min-height: 100%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
  .mob-view-draw {
    @media only screen and (min-width: 780px) {
      display: none;
    }
  }
  .web-view-drawname {
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
}
.drawing-input-cont {
  display: flex;
  // height: 100%;
  // justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 30px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.create-btn {
  width: 30% !important;
  margin-top: 15px;
  @media only screen and (max-width: 960px) {
    width: 60% !important;
  }
}

.form-side p {
  color: grey;

  margin-top: 15px;
  font-size: 14px;

  font-weight: bold;
}
.vr {
  // background-color: #808080 !important;
  margin-top: 5vh !important;
  border-left: 1px solid #0000 !important;
  // align-self: flex-end;
  min-height: 30vh !important;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.circle-select {
  width: 100% !important;
}
.checkbox-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  .ant-checkbox-group {
    display: flex !important;
    flex-direction: column !important;
    .ant-checkbox-wrapper :last-child {
      color: grey;
      font-weight: bold;
    }
  }
}
