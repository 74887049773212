@use '../../master.scss';

.share-btn-center-modal {
  width: 120px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background: #00aca8;
  margin: auto;
  @media only screen and (max-width: 960px) {
    margin-top: 20px;
  }
  p {
    color: white;
    height: 8px;
  }
}

.bulk-share-modal-emails {
  border-radius: 10px;
  overflow: hidden;
  height: 400px;

  .ant-modal-title {
    color: master.$theme-color;
  }
  .ant-modal-body {
    // height: 600px;
    overflow-y: scroll;
    padding: 0px !important;
    @media only screen and (max-width: 960px) {
      height: 400px;
      overflow: auto !important;
    }
  }
}

.share-wrapper-div {
  width: 428px;
  min-height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    width: 300px;
    min-height: 300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}
.cross-Icon-share {
  width: 18px;
  height: 18px;
}
.cancel-btn-center-modal {
  width: 120px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  margin: 0 auto;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background: #fff;
  margin: auto;
  border: 1px solid #00aca8;
  p {
    color: black;
    height: 8px;
  }
}

.share-email-input {
  border-radius: 20px !important;
  border-color: #4ca9a7 !important;
  min-height: 40px !important;
  width: 280px !important;
  margin: 0 auto;
}

.share-email-input-div {
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
}

.cancel-share-div {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
}

.drawings-name {
  font-size: 12px;
}

.drawings-name-div {
  height: auto;
  max-height: 50px;
  overflow-y: auto;
  margin: 0 auto;
  flex: none;
  margin-top: 10px;
  p {
    color: #00aca8;
  }
}

.share-email-div {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  flex-direction: column;
}

.share-email-txt {
  font-weight: 700;
  font-size: 22px;
  color: #00aca8;
  margin-left: 10px;
  margin-top: 0px;
}

.share-email-to {
  font-weight: 700;
  font-size: 22px;
  color: black;
  height: 10px;
}

.comma-line-bulk-share {
  margin: 0 auto;
  margin-top: 20px;
  p {
    font-size: 12px;
    color: black;
    font-weight: 400;
    text-align: center;
  }
}

.share-email-list-view {
  height: 100px;
  width: 400px;
  border: 1px solid #4ca9a7;
  border-radius: 15px;
  background-color: #d5d5d5;
  padding: 10px 10px;
  ul {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    overflow-y: auto;
    padding: 0px;
    margin: 0px;

    li {
      font-size: 15px;
      color: black;
      display: block;
    }
    &::-webkit-scrollbar {
      background-color: white;
      width: 10px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #fff;
      width: 5px;
      margin: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      height: 5px;
      width: 5px;
      border-radius: 5px;
    }

    @media only screen and (max-width: 960px) {
      width: 330px !important;
      p {
        font-size: 10px;
      }
    }
  }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: #555;
  // }
  @media only screen and (max-width: 960px) {
    width: 300px !important;
  }
}
.share-email-list {
  height: 100px;
  width: 400px;
  border: 1px solid #4ca9a7;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;

  @media only screen and (max-width: 960px) {
    width: 300px;
    height: 130px;
  }

  p {
    font-size: 15px;
    color: black;
    flex: 1;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.share-emails-list-div {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  margin: 0 auto;
  p {
    height: 10px;
    font-size: 15px;
    color: black;
    font-weight: bold;
    margin-left: 5px;
  }
}

.inner-div-share-listing {
  display: flex;
  flex-direction: row;
  margin: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: row;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sharing-user {
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 20px;
}

.share-to-txt {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 0px;
  p {
    font-weight: 700;
    font-size: 22px;
  }
}
.email-share-user-txt {
  font-size: 18px;
  color: #00aca8;
  display: flex;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}

.error-txt-share-emails {
  margin-top: 3px;
  color: red !important;
  font-size: 12px !important;
  font-weight: 300 !important;
}

.share-modal-wrapper-div {
  width: 428px;
  min-height: 260px;
  border-radius: 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    width: 300px;
    min-height: 403px !important;
    border-radius: 25px;
    margin: 0 auto;
    display: flex;
    // overflow: auto !important;
  }
}
