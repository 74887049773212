.login-container {
  width: 100%;
  min-height: 100vh;
  h5 {
    color: #fff;
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
}
.gotologinwrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .goToLoginbtn {
    background-color: #00aca8 !important;
    margin-top: 20px !important;
    border-radius: 8px;
    color: #fff !important;
    padding: 0px 15px !important;

    border: 1px solid #00aca8;
  }
}
.links-wrapper {
  width: 80%;
  margin: 0 auto;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   flex-wrap: wrap;

  @media (max-width: 512px) {
    flex-direction: column;
  }
  .btn-links {
    color: #fff;
  }
}
