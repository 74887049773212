.step4-main-div {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;

  @media only screen and (max-width: 960px) {
    margin: 0 auto;
  }
}
.drawings-status-selection-txt {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  span {
    font-weight: bold;
  }
}
.Drawings-status {
  width: 303px;
  height: 59px;
  border-radius: 22px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #d8d8d8;
  justify-items: center;
}

.drawinngs-status-txt {
  font-size: 10px;
  text-align: center;
  width: 270px;
}

.drawings-status-div {
  width: 366px;
  height: 50px;
  border: 2px solid #00aca8;
  border-radius: 25px;
  justify-content: space-between;
  display: flex;
  margin-top: 20px;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    width: 250px;
  }
}
.drawings-ant-dropdown {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 336px;
  align-items: center;
  margin-left: 10px;
  @media only screen and (max-width: 960px) {
    width: 230px;
  }
}
.notes-step4-input {
  width: 340px !important;
  height: 132px;
  display: flex !important;
  border-radius: 25px !important;
  margin-top: 25px !important;
  padding: 20px;
  resize: none;
}
