.sidebar-cont {
  background: #fff;
  width: 22%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 100%;
    flex-direction: row;
  }
}
.sidebar-cont h5 {
  color: #404041;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

hr {
  background-color: #4ca9a7;
  width: 100%;
  border: none;
  opacity: 1;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.projectDetailSidebar {
  .sidebar-options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      font-size: 14px;
      text-align: right;
      margin-left: 20px !important;
      @media only screen and (max-width: 960px) {
        font-size: 12px !important;
        text-align: center;

        margin-left: 0 !important;
      }
      @media only screen and (max-width: 703px) {
        // font-size: 8px !important;
        // font-weight: bold !important;
        text-align: center;
        margin-left: 0 !important;
      }
    }
    img {
      @media only screen and (max-width: 703px) {
        width: 22px;
      }
    }
    @media only screen and (max-width: 960px) {
      ///width: 50%;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
    }
  }
}

.sidebar-logo {
  width: 30px;
}
.sidebar-logo-bulk-upload {
  width: 20px !important;
  @media only screen and (max-width: 960px) {
    width: 15px !important;
  }
}
.resp-draw-reg {
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.resp-draw-reg-2 {
  display: none;
  @media only screen and (max-width: 960px) {
    display: unset;
  }
}

.download-register-modal-div {
  width: 230px;
  height: 120px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff;
  border: 1px solid #fff;
  position: absolute;
  left: 100px;
  z-index: 111;
  // box-shadow: 1px 2px 9px #f4aab9;
  padding: 0.5em;
  display: flex;
  margin: 0 auto;
  border: 0.2px solid black;
  flex-direction: column;
  p {
    font-size: 10px;
    font-weight: bold;
    margin: 0 auto;
    display: flex;
    text-align: center;
  }
  @media only screen and (max-width: 960px) {
    // display: none;
    position: fixed;
    width: 93%;
    margin-top: 11px;
    left: 0;
    right: 0;
  }
}

.download-register-pdf-cvs {
  flex-direction: row;
  display: flex;
  align-self: center;
}

.image-pfd-csv {
  width: 30px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
}

.line-between-files {
  height: 40px;
  width: 0.4px;
  display: flex;
  background-color: #707070;
  margin: 0 auto;
  align-self: center;
}
.projectDetailSidebar {
  @media (max-width: 767px) {
    .sidebar-options,
    .sidebar-options-div {
      max-width: 20%;
      width: 100% !important;
    }
  }
}
.sidebar-options-div {
  width: 100%;
  justify-content: center;
  position: relative;
  align-items: center;

  @media only screen and (max-width: 960px) {
    width: auto;
    align-self: center;
    margin-right: 6px;
  }
  @media (max-width: 767px) {
    .sidebar-options {
      max-width: 100%;
    }
  }
}
