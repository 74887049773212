.content-cont {
  background: #fff;
  width: 78%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh !important;
  min-height: 80vh !important;
  height: 100% !important;
  justify-content: flex-start !important;
  overflow-y: scroll;
  position: relative;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}
.content-cont::-webkit-scrollbar {
  display: none;
}
.content-cont h5 {
  color: #404041;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}
.content-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #00aca8;
  padding-bottom: 10px;
}

.header-content {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-input {
  border-radius: 20px !important;
  border-color: #4ca9a7 !important;
  background-color: #ebebeb !important;
  min-height: 40px !important;
  width: 380px !important;
}
.search-input::placeholder {
  color: #606060 !important;
}
.search-input:focus::placeholder {
  color: transparent !important;
}
.ant-form-item {
  margin: 0 !important;
}
.ellipsis {
  background-color: #fff;
  cursor: pointer;
  text-decoration: none !important;
  width: 20px !important;
}
th {
  font-weight: bold !important;
  border-color: #404041 !important;
}
th::before {
  display: none !important;
}
td {
  border-bottom: 0.5px solid RGB(62, 63, 58, 0.3) !important;
}
.popover-options {
  min-width: none;
}
.ant-popover-inner-content {
  min-width: 160px;
  padding: 0 !important;
}
.ant-popover-inner {
  border-radius: 12px !important;
}
.ant-popover-arrow {
  display: none !important;
}
.options-cont {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  border-radius: 12px;
}
.options-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  background-color: #ebebeb;
  border-radius: 12px;
  cursor: pointer;
}
.options-item:hover {
  background-color: #d7d7d7;
}
.options-item:hover a {
  color: #00aca8;
}
.options-item a {
  color: #606060;
  width: 80%;
  font-weight: 300;
}
.notification-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 5px 5.5px 5px 5px;
  height: 20px;
  width: 20px;
  border: 1px solid transparent;
  border-radius: 15px;
  background: #ee5755;
}
.notification-circle p {
  color: #fff;
  margin: 0;
  font-size: 11px;
}
.ant-table-wrapper {
  width: 100%;
  overflow-x: auto !important;
}
.ant-popover {
  padding: 0 !important;
}
.table-row {
  cursor: pointer;
}
.invites-list-cont {
  display: none;
  @media only screen and (max-width: 960px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    // border-top: 2px solid #4ca9a7;
    border-bottom: 1px solid #4ca9a7;
    padding: 10px;
    margin-bottom: 15px;
  }
  p {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}
.resp-invites-cont {
  display: none;
  @media only screen and (max-width: 960px) {
    display: unset;
    padding: 10px;
  }
}

.table-cont {
  padding-top: 0px !important;
}

.table-cont-dashboad {
  padding-top: 0px !important;
  overflow-y: auto;
  width: 100%;
  padding-top: 50px;
  min-height: 100px;
}

.search-input-archived {
  border-radius: 20px !important;
  border-color: #4ca9a7 !important;
  background-color: #ebebeb !important;
  min-height: 40px !important;
  width: 250px !important;
}

.header-content-archived {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 960px) {
    width: 100%;
    gap: 10px;
    flex-direction: column;
  }
}

.heading-content-txt-div {
  flex: 1;
  h5: {
    margin: 0 auto;
  }
}
