.sec-title {
  font-size: 55px;
  font-weight: 700;
  line-height: 67px;
  z-index: 9;
  color: #22394e;
  margin-bottom: 0;
  span {
    color: #2098f2;
  }
  @media only screen and (max-width: 991px) {
    font-size: 35px;
    line-height: normal;
  }
  @media screen and (max-width: 763px) {
    font-size: 24px;
  }
}
.sec-description {
  color: #fff;
  font-size: 18px;
  width: 715px;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 40px;
  @media screen and (max-width: 763px) {
    font-size: 13px;
  }
}
