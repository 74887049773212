.activity-cont {
  flex-direction: column;
  align-items: center;
}
.activity-input {
  width: 30% !important;
  @media only screen and (max-width: 960px) {
    // width: 85% !important;
    // padding: 10px !important;
    display: none !important;
  }
}
.draw-activity-cont {
  width: 32.5%;
  // max-height: 79vh;
  min-height: 79vh;
  justify-content: flex-start;
  gap: 5px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    // flex-direction: column;
    // gap: 10px;
  }
}
.select-curr-activity {
  display: flex;
  padding: 35px 10px 25px 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  background-color: #fff;
  border-radius: 8px;
  .select-activity {
    width: 90%;
  }
  @media only screen and (min-width: 960px) {
    display: none;
  }
}
.activity-content-area {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (min-width: 960px) {
    display: none;
  }
  @media only screen and (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }
}
.activity-content-area-web {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.activity-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #00aca8;
  padding-bottom: 10px;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.activity-info {
  font-size: 13px;
  color: #3e3f3a;
}
