.content-cont {
  background: #fff;
  width: 80%;
  padding: 10px 0px;
  // padding-top: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 79vh !important;
  overflow-y: scroll;
  position: relative;
  @media only screen and (max-width: 960px) {
    width: 100%;
    padding: 0px;
  }
}
.content-cont::-webkit-scrollbar {
  display: none;
}
.content-cont h5 {
  color: #404041;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}
.content-header {
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #00aca8;
  padding: 0 10px 10px 10px;
  margin: 0 10px 0 10px;

  @media only screen and (max-width: 960px) {
    display: none !important;
  }
}
.content-header-new {
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #00aca8;
  padding: 0 10px 10px 10px;
  margin: 0 10px 0 10px;
  @media only screen and (max-width: 960px) {
    display: none !important;
  }
}
.header-content {
  width: 60%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0.8;
  align-content: center;
}

.search-input {
  border-radius: 20px !important;
  border-color: #4ca9a7 !important;
  background-color: #ebebeb !important;
  min-height: 40px !important;
  width: 380px !important;
}

.search-input::placeholder {
  color: #606060 !important;
}
.search-input:focus::placeholder {
  color: transparent !important;
}
.ant-form-item {
  margin: 0 !important;
}
.ellipsis.popover-options {
  background-color: #fff;
  cursor: pointer;
  text-decoration: none !important;
  width: 20px !important;
  height: 20px;
  align-self: center;
}
th {
  font-weight: bold !important;
  border-color: #404041 !important;
}
th::before {
  display: none !important;
}
td {
  border-bottom: 0.5px solid RGB(62, 63, 58, 0.3) !important;
}
.popover-options {
  min-width: none;
}
.ant-popover-inner-content {
  min-width: 160px;
  padding: 0 !important;
}
.ant-popover-inner {
  border-radius: 12px !important;
}
.ant-popover-arrow {
  display: none !important;
}
.options-cont {
  display: flex;
  flex-direction: column;
  background-color: #ebebeb;
  border-radius: 12px;
}
.options-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  background-color: #ebebeb;
  border-radius: 12px;
  cursor: pointer;
}
.options-item:hover {
  background-color: #d7d7d7;
}
.options-item:hover a {
  color: #00aca8;
}
.options-item a {
  color: #606060;
  width: 80%;
  font-weight: 300;
}
.detail-notification-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 5px 5.5px 5px 5px;
  height: 10px;
  width: 10px;
  border: 1px solid transparent;
  border-radius: 15px;
  background: #65beb9;
}
.notification-circle p {
  color: #fff;
  margin: 0;
  font-size: 12px;
}
.ant-table-wrapper {
  width: 100%;
  th {
    padding: 10px !important;
    @media only screen and (max-width: 800px) {
      font-size: 12px !important;
      padding: 5px !important;
    }
  }
}
.title-col {
  display: inline;
  font-size: 12px;
  @media only screen and (max-width: 700px) {
    display: none;
  }
}
.title {
  display: none;
  font-size: 12px;
  @media only screen and (max-width: 700px) {
    display: inline;
  }
}
.ant-popover {
  padding: 0 !important;
}
.table-row {
  cursor: pointer;
  font-size: 12px;
  height: 55px;
}
.circle-label-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 5px;
  padding-left: 15px;
  background-color: #65beb9;
  margin-top: 10px;
}
.circle-label-cont p {
  margin: 0;
  color: #fff;
  font-size: 1.6em;
  font-weight: 500;
}
.options-table {
  display: flex;
  gap: 15px;
  width: 180;
  margin-left: 10px;
  @media only screen and (max-width: 525px) {
    width: 40px !important;
  }
}
.bell-icon-column {
  width: 18px !important;
  // @media only screen and (max-width: 575px) {
  //   width: 40px !important;
  // }
  // @media only screen and (max-width: 525px) {
  //   width: 60px !important;
  // }
}
.comment-show-for-mobile {
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.options-table-wrapper {
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    display: none;
  }
}
.options-cont {
  .web-options {
    display: inline;
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
  .mob-options {
    display: none;
    @media only screen and (max-width: 800px) {
      display: inline;
    }
  }
}

.options-icons {
  max-width: 15px;
}
.table-cont {
  overflow-y: auto;
  width: 100%;
  padding-top: 50px;
  min-height: 100px;
  //max-height: 300px;
  height: calc(100% - 124px);
}
.loader-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.table-cont::-webkit-scrollbar {
  display: none;
}

.circles-pop-cont {
  display: flex;
  flex-direction: column;
  background-color: #f1f1f1;
  border-radius: 12px;
}
.circles-pop-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: flex-start;
  background-color: #f1f1f1;
  border-radius: 12px;
}
.circles-pop-item p {
  color: #606060;
  width: 80%;
  margin: 0;
  font-weight: 300;
}
.first-pop {
  border-bottom: 1px solid #4ca9a7;
  border-radius: 0;
}

.uploaded_txt {
  align-items: center;

  justify-items: center;

  display: flex;
  justify-content: center;
}
.uploaded_txt p {
  height: 10px;
}
.uploadedTxt {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #00aca8;
  font-size: 12px;
  flex: 1;
  span {
    font-size: 10px !important;
    color: gray;
  }
}

.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  padding: 20px 0px 15px 20px;
  color: #00aca8;
  border-bottom: 1px solid #00aca8;
}

.bulk-download {
  width: 100%;
  height: 68px;
  background-color: #e6e6e6;
  align-items: center;
  justify-items: center;
  align-items: center;
  align-self: flex-end;
  display: flex;
  padding-left: 10px;
  position: absolute;
  bottom: 0;
}

.btn-upload {
  width: 205px;
  height: 39px;
  align-items: center;
  background-color: #00aca8;
  border-radius: 18px;
  display: flex;
  border-width: 0px;
  @media only screen and (max-width: 960px) {
    width: 30px;
    height: 30px;
  }
}
.btn-upload-unselected {
  width: 205px;
  height: 39px;
  align-items: center;
  background-color: #d8d8d8;
  border-radius: 18px;
  display: flex;
  border-width: 0px;
  @media only screen and (max-width: 960px) {
    width: 30px;
    height: 30px;
  }
}
.download-img-bulk {
  margin: 10px;
  margin-left: 20px;
  fill: black;
  @media only screen and (max-width: 960px) {
    margin: auto !important;
  }
}
.download-txt-bulk {
  color: white;
  font-size: 12px;
  height: 8px;
  font-weight: 600;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.select-checkBox-all {
  align-items: flex-end;

  display: flex;
}

.checkBox-style {
  width: 16px;
  height: 16px;
  align-self: flex-end;
  margin-right: 5px;
}
.checkBox-txt {
  margin: 0 auto;
  font-size: 12px;
  font-weight: 400;
}

.files-btn {
  background: transparent;
  border: 0;
  padding: 0;
  height: auto;
  width: max-content;
}

.disable-txt-dwg {
  color: #d8d8d8;
}
.sorting-icon-img {
  display: none;
}
.table-cont .custom-drawing-table {
  &:nth-child(2) {
    .sorting-icon-img {
      display: initial;
    }
  }
}
.custom-drawing-table {
  .ant-table-thead {
    .ant-table-cell:nth-child(1) {
      width: 40px;
      @media only screen and (max-width: 800px) {
        width: 20px;
      }
    }
    .ant-table-cell:nth-child(2) {
      width: 30px;
      @media only screen and (max-width: 800px) {
        width: 20px;
      }
    }
    .ant-table-cell:nth-child(3) {
      width: 100px;
      @media only screen and (max-width: 800px) {
        width: 60px;
      }
    }
    .ant-table-cell:nth-child(4) {
      width: 200px;
      @media only screen and (max-width: 800px) {
        width: 60px;
      }
    }
    .ant-table-cell:nth-child(5) {
      width: 100px;
      @media only screen and (max-width: 800px) {
        width: 60px;
      }
    }
    .ant-table-cell:nth-child(6) {
      width: 100px;
      @media only screen and (max-width: 800px) {
        width: 150px;
      }
    }
    .ant-table-cell:nth-child(7) {
      width: 80px;
      @media only screen and (max-width: 800px) {
        width: 50px;
      }
    }
    .ant-table-cell:nth-child(8) {
      width: 130px;
      @media only screen and (max-width: 800px) {
        width: 60px;
      }
    }
    .ant-table-cell:nth-child(9) {
      width: 180px;
      @media only screen and (max-width: 800px) {
        width: 60px;
      }
    }
  }
}

.all-checkbox-drawings {
  // flex: 0.5;
  margin-left: 6px;
}
.btn-upload-clear {
  width: 205px;
  height: 38px;
  align-items: center;
  background-color: white;
  border-radius: 18px;
  border: 1px solid #00aca8;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}
.txt-clean-all {
  color: black;
  font-size: 12px;
  height: 8px;
}
.bulk-btns-div {
  flex: 1;
  flex-direction: row;
  display: flex;
}

.count-notification-circle {
  background-color: red;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 12px;
  }
}

.name-count-div {
  flex: 0.98;
  flex-direction: row;
  display: flex;
  width: 600px;
}

.drawings-count-per-circle {
  font-size: 12px !important;
  margin-right: 5px !important;
}

.sort-dropdown-div {
  margin: 0 auto;
  display: flex;
  width: 230px;
  padding: 5px;
  border-radius: 25px;
  border: 2px solid #00aca8;
  margin-right: 20px;
}

.sorting-item {
  font-size: 12px;
  color: #000000;
  //border-width: 2px;
  // border-color: #000000;
  .ant-dropdown-menu-title-content {
    flex-direction: row;
    display: flex;
    p {
      flex: 1;
      height: 10px;
      align-self: end;
      font-size: 12px;
    }
    .delimiter-circle-item-show {
      border-radius: 20px;
      background-color: #00aca8;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      p {
        align-self: center;
        color: #fff;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
}
.sorting-dropdrown {
  min-width: 153px;
  left: 498px;
  top: 167px;
  cursor: pointer;
}
.sort-selected-txt {
  flex: 1;
  height: 10px;
  width: 180px;
  font-size: 12px;
  margin-left: 10px;
}

.sorting-menu-item {
  width: 220px;
  height: 172px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 50px;
  overflow-y: scroll;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.search-drawings-div {
  margin-top: 10px;
  display: flex;
  justify-items: center;
  justify-content: center;
  .search-input {
    width: 250px !important;
  }
}

.loader-div {
  margin: 0 auto;
}

.drawings-item-notes {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100px;
  @media only screen and (max-width: 800px) {
    width: 50px;
  }
}
.drawings-item-notes-txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: left;
}
.drawings-item-dname-txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
}

.div-columns-width-80 {
  width: 80px;
  @media only screen and (max-width: 800px) {
    width: 40px;
  }
}
.div-columns-width-Dname {
  width: 200px;
  @media only screen and (max-width: 800px) {
    width: 100px;
  }
}
.div-columns-width-100 {
  width: 100px;
  @media only screen and (max-width: 800px) {
    width: 50px;
  }
}
.div-columns-width-status {
  width: 100px;
  @media only screen and (max-width: 800px) {
    width: 80px;
  }
}
.circle-name-drawings {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media only screen and (max-width: 800px) {
    width: 200px;
  }
}
