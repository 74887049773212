.main-parent-div {
  height: 400px;
  overflow: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.mainTxtDiv {
  width: 600px;
  height: 90px;
  background-color: rgba(198, 198, 198, 0.68);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 20px;
  @media only screen and (max-width: 960px) {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.choose-folder-btn {
  width: 200px;
  height: 50px;
  background-color: #00aca8;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
  @media only screen and (max-width: 960px) {
  }
}

.main-choose-txt {
  color: black;
  font-size: 16px;
  font-weight: 400;
  height: 1px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
    font-size: 15px;
    margin: 20px;
    align-self: center;
    text-align: center;
    align-items: center;
  }
}
.choose-txt {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.upload-files-list {
  height: 20px;
  // width: 180px;
  // margin: 0 auto;
  // align-self: center;
  // overflow: auto;
  margin-top: 10px;
  display: flex;
}

.files-name-txt {
  color: black;
  font-size: 18px;
  margin: 0 auto;
  display: flex;
}
.parent-div-upload-lists-files {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: green;
  justify-items: center;
}

.step4-menu-item {
  width: 350px;
  height: 130px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 50px;
  overflow-y: scroll;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.circle-dropdown-div {
  margin: 0 auto;
  display: flex;
  width: 230px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 25px;
  border: 2px solid #00aca8;

  display: flex;
}

.circle-dropdown-txt {
  flex: 1;
  height: 10px;
  width: 180px;
  margin: 10;
  // overflow: hidden;
  margin-left: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.step1-dropdown-div {
  width: 153px;
  // left: 498px;
  // top: 167px;
  height: 30px;
  cursor: pointer;
}

.step1-circle-error {
  margin: 0 auto;
  color: red;
  font-size: 12px;
  display: flex;
  width: fit-content;
}
