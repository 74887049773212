.main-div-bulk {
  justify-content: center;
  align-items: center;

  flex-direction: column;
  display: flex;
  margin-top: 50px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.txt1 {
  color: white;
  font-size: 28px;

  //   font-weight: 600;
}
.bulk-download-txt2 {
  color: white;
  font-size: 18px;
  font-weight: 100;
  text-align: center;
}

.bulk-download-txt3 {
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  padding: 5px 10px;
  color: grey;
}

.bulk-download-mainDiv {
  width: 900px;
  // height: 500px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;

  align-content: center;
  justify-content: center;

  @media only screen and (max-width: 960px) {
    width: 100%;
    // height: 550px;
  }
}
.bulk-download-following-txt {
  color: black;
  font-size: 20px;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  span {
    color: #00aca8;
  }
}

.bulk-download-inner-div {
  width: 100%;
  height: 261px;
  //   display: flex;
  overflow-y: scroll;
  border: 1px solid #00aca8;
  border-radius: 20px;
  margin: 0 auto;
}

.bulk-download-row-table-header {
  display: flex;
  flex-direction: row;
  margin: 20px 20px 0px 0px;
  justify-content: space-around;
}

.bulk-download-drawings-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 15px 20px 0px 30px;
  border-bottom: 0.5px solid #000;
}

.mainn-bulk-donwload-div {
  margin: 0 auto;
  width: 85%;
  margin-top: 50px;
}

.item-img-div {
  width: 100px;
  // align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5px;
  img {
    cursor: pointer;
  }
}

.bulk-download-button-link {
  width: 260px;
  height: 50px;
  border-radius: 25px;
  background-color: #00aca8;
  align-items: center;
  justify-content: center;
  border: 0px solid;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
  p {
    color: white;
    height: 10px;
  }
}

.bulk-download-btn-disable {
  background-color: #d8d8d8;
  width: 260px;
  height: 50px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  border: 0px solid;
  margin: 0 auto;
  display: flex;
  margin-top: 30px;
  p {
    color: white;
    height: 10px;
  }
}

.BS_item_DNumber_PC {
  width: 150px;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.BS_item_DNumber_MO {
  width: 10px;
  display: none;
  @media only screen and (max-width: 960px) {
    display: flex;
    width: 100px;
  }
}
.BS_item_DName_PC {
  width: 250px;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.BS_item_DName_MO {
  width: 10px;
  display: none;
  @media only screen and (max-width: 960px) {
    display: flex;
    width: 100px;
    margin-left: 5px;
  }
}
.BS_item_LUPDATE_PC {
  width: 100px;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.BS_item_LUPDATE_MO {
  display: none;
  @media only screen and (max-width: 960px) {
    display: flex;
    width: 70px;
    margin-left: 5px;
  }
}
