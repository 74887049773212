@use '../../master.scss';

.confirm-btn-notification-center-modal {
  width: 120px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  margin: 0;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background: #00aca8;
  cursor: pointer;
  p {
    color: white;
    height: 8px;
  }
}
.cancel-btn-notification-center-modal {
  width: 120px;
  height: 42px;
  border-radius: 21px;
  display: flex;
  margin: 0;
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #00aca8;
  cursor: pointer;
  p {
    color: black;
    height: 8px;
  }
}
.cross-Icon-share {
  width: 18px;
  height: 18px;
}

.cancel-share-div {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
}

.notification-wrapper-div {
  width: 428px;
  height: 200px;
  margin: 0 auto;
  @media only screen and (max-width: 960px) {
    width: 328px;
    height: 250px;
    margin: 0 auto;
  }
}

.bottom-btn-notification {
  flex-direction: row;
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  @media only screen and (max-width: 960px) {
    margin-top: 50px;
    margin-bottom: 10px;
  }
}

.notification-dis {
  font-size: 15px;
  font-weight: 400;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 15px;
  color: #00aca8;
  @media only screen and (max-width: 960px) {
    margin: 0 auto;
    text-align: center;
  }
}
