.revision-activity-col1 {
  h6 {
    color: white;
    text-align: center;
  }
  h5 {
    text-align: center;
    color: white;
  }
}
.revision-activity-col2 {
  display: flex;
  flex-direction: column;
  background-color: white !important;
  border-radius: 10px;
  padding: 20px;
  height: 70vh;
  .title {
    text-align: center;
    color: #404041;
    font-size: 24px;
    border-bottom: 1px solid #4ca9a7;
    padding-bottom: 10px;
  }
  div {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 10px;
    span {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      h6 {
        font-size: 14px;
      }
    }
  }
  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  div::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}
