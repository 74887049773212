.archived {
  padding: 110px 15px 20px 15px !important;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.revisions-cont {
  width: 70%;
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  min-height: 80vh;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}
.revisions-header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-bottom: 1px solid #4ca9a7;
  h4 {
    margin: 0;
  }
}
.revisions-content {
  .ant-table-cell:last-child {
    span {
      background-color: transparent;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-table-cell {
    padding: 0;
    span {
      display: flex;
      padding: 10px 5px;
      height: 100% !important;
      width: 100%;
      // background-color: #4ca9a7;
    }
    .span-cancelled {
      display: flex;
      padding: 10px 5px;
      height: 100% !important;
      width: 100%;
      background-color: #e67e22;
    }
    .span-remove {
      display: flex;
      padding: 10px 5px;
      height: 100% !important;
      width: 100%;
      background-color: #e74c3c;
    }
  }
}
.search-title-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  .input-wrapper {
    position: absolute;
    right: 0;
    width: 20% !important;
    border-radius: 10px;
    border: 1px solid #4ca9a7;
    @media screen and (max-width: 990px) {
      display: none;
    }
  }
}

.drawings-revions {
  cursor: pointer;
}

.download-txt-div {
  display: flex;
  flex-direction: row;
  width: 100px;
  justify-content: space-evenly;
  align-content: center;
  p {
    color: black;
    font-size: 12px;
    height: 10px;
  }
}

.dwg-disable-txt-revisions {
  color: #d8d8d8 !important;
}
