.mainDivStep2 {
  width: 683px;
  height: 250px;
  border-radius: 20px;
  background-color: #d8d8d8;
  margin: 0 auto;
  margin-top: 20px;
  @media only screen and (max-width: 960px) {
    width: 100%;
    font-size: 12px;
  }
}

.contentTxt {
  padding: 20px;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  span {
    font-weight: bold;
    font-size: 16px;
  }
}
.exampleNote {
  width: 200px;
  height: 110px;
  border: 1px solid #000000;
  border-radius: 20px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    height: 80px;
  }
}
.exampleTxt {
  font-size: 16px;
  color: #000000;
  span {
    font-size: 16px;
    font-weight: 'bold';
  }
  @media only screen and (max-width: 960px) {
    font-size: 12px;
  }
}

.selected-delimiter-div {
  margin: 0 auto;
  display: flex;
  width: 180px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #00aca8;
  cursor: pointer;
}

.selected-delimiter {
  width: 148px;
  height: 26px;
}

.selected-delimiter-txt {
  flex: 1;
  height: 10px;
  width: 130px;
  font-size: 14px;
}

.delimiter-txt {
  margin: 0 auto;
  color: #00aca8;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
  text-align: center;
}

.ant-dropdown-link {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-items: center;
  align-items: center;
}

.delimiter-menu-item {
  width: 165px;
  height: 144px;
  background-color: #fff;
  border-radius: 15px;
}

.ant-dropdown-menu {
  border-radius: 15px !important;
}
.ant-dropdown-menu-item {
  border-bottom: 1.5px solid #707070;
  margin-left: 8px !important;
  margin-right: 8px !important;
  &:last-child {
    border-bottom: 0px;
  }
}

.delimiter-item {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  //border-width: 2px;
  // border-color: #000000;
  .ant-dropdown-menu-title-content {
    flex-direction: row;
    display: flex;
    p {
      flex: 1;
      height: 10px;
      align-self: end;
    }
    .delimiter-circle-item-show {
      border-radius: 20px;
      background-color: #00aca8;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      p {
        align-self: center;
        color: #fff;
        height: 100%;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
}
