.main-div-bulk {
  justify-content: center;
  align-items: center;

  flex-direction: column;
  display: flex;
  margin-top: 50px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.txt1 {
  color: white;
  font-size: 28px;

  //   font-weight: 600;
}
.txt2 {
  color: white;
  font-size: 22px;
  font-weight: 100;
}
.mainDivStep {
  width: 1000px;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}
.stepHeader {
  display: flex;
  //align-content: space-between;
  //justify-content: space-between;
  height: 70px;
}

.stepHeading {
  align-items: center;
  display: flex;
  margin: 0 auto;
  // margin-left: 20px;
}

.stepCountDiv {
  height: 68px;
  width: 124px;
  background-color: #00aca8;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.stepTxtCount {
  color: white;
  font-size: 18px;
  font-weight: 400;
  margin: 0 auto;
  align-items: center;
}

.stepHeadingTxt {
  font-size: 25px;
  color: black;
  font-weight: 700;
  margin: 0 auto;
  align-items: center;
}

.stepInnerDiv {
  height: 400px;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 960px) {
    justify-content: flex-start;
    overflow: scroll;
  }
}
.drawings-error-inner-div {
  height: 425px;
  justify-content: center;
  display: flex;
  @media only screen and (max-width: 960px) {
    justify-content: flex-start;
    overflow: scroll;
  }
}

.bottomDiv {
  height: 70px;
  width: 80%;
  display: flex;
  align-self: center;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.next-Btn {
  width: 106px;
  height: 42px;
  display: flex;
  align-items: center;
  align-items: center;
  background-color: #00aca8;
  border-radius: 20px;
  justify-content: center;
  cursor: pointer;
}
.start-uploading-Btn {
  width: 206px;
  height: 42px;
  display: flex;
  align-items: center;
  align-items: center;
  background-color: #00aca8;
  border-radius: 20px;
  justify-content: center;
  cursor: pointer;
}
.next-txt {
  color: white;
  font-size: 18px;
  font-weight: 400;
  height: 10px;
}
.back-Btn {
  width: 106px;
  height: 42px;
  display: flex;
  align-items: center;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  border: 1px solid #00aca8;
}
.back-txt {
  color: #00aca8;
  font-size: 18px;
  font-weight: 400;
  height: 10px;
}

.uploading-bulk-text-heading {
  font-size: 28px;
}

.loading-div-bulk {
  margin: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  p {
    color: white;
  }
  span {
    color: #00aca8;
  }
}

.progress {
  height: 30px !important;
  width: 350px !important;
  border-radius: 25px;
  // --bs-progress-bar-bg: #00ACA8; !important
}

.progress-bar {
  background-color: #00aca8;
}

.drawings-error-main-div {
  margin: 0 auto;
  width: 900px;
  height: 520px;
  background-color: white;
  border-radius: 10px;
  margin-top: 20px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.download-error-log-txt {
  font-size: 12px;
  color: #4da9a7;
  cursor: pointer;
}
