// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

html,
body {
  // scroll-behavior: smooth;
  // min-height: 95%;
  margin: 0;
  background-color: #fafcfe !important;
  font-family: 'Poppins' !important;
}
*,
input,
button {
  outline: none;
  margin: 0;
  padding: 0;
  font-family: 'Poppins' !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}
img {
  max-width: 100%;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  background: #fff;
  justify-content: center;
}

iframe:not(.confirm-site-iframe-logo-wrapper *) {
  display: none !important;
}

.ant-form-item-with-help .ant-form-item-explain {
  font-size: 12px;
}
.__react_modal_image__modal_container {
  z-index: 9999999999 !important;
}
.remove-btn {
  background-color: #e74c3c !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.container {
  margin: 0px auto !important;
  padding: 0px !important;
  max-width: 1560px;
  width: 100%;
}

.rootBody {
  background-image: url('./assets/backgrounds/moving-through-stars-in-space_-1zccenlb__F0000.png');
  background-size: cover;
  background-repeat: no-repeat;
}
