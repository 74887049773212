@import '../../../theme/_colors';

.dashboard {
  padding: 110px 15px 20px 15px !important;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  width: 100vw;
  max-width: 1560px;
  // margin-top: 80px;
  // @media only screen and (max-width: 960px) {
  //   flex-direction: column;
  // }
  .col-1-main-wrapper {
    display: flex;
    padding: 20px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: #fff;
    .col-1-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      h3 {
        text-align: center;
        padding: 0 0 20px 0;
        font-size: 24px;
        font-weight: 300;

        color: $text-primary;
        border-bottom: 1px solid #00aca8;
        @media only screen and (max-width: 991px) {
          // display: none;
        }
      }
      h4 {
        font-family: Arial, Helvetica, sans-serif !important;
        strong {
          font-family: Arial, Helvetica, sans-serif !important;
        }
        margin: 15px 0;
        line-height: 20px;
        color: $text-primary;
        font-size: 18px;
        @media only screen and (max-width: 991px) {
          // display: none;
        }
      }
      h6 {
        margin-top: 10px;
        color: #3e3f3a;
        font-size: 14px;
        padding: 0 50px;
        text-align: center;
        @media only screen and (max-width: 991px) {
          // display: none;
        }
      }
      .button-wrapper {
        width: 100%;
        display: flex;

        justify-content: space-between;
        .ant-btn {
          width: 50%;
          background-color: white;
          color: #000;
          font-size: 20px;
          height: 50px;
          // padding: 20px 0;
        }
        .ant-btn:focus {
          background-color: #00aca8;
        }
      }
      .col-3-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        height: 100%;
        flex-direction: column;
        span {
          display: flex;
          align-items: center;
          cursor: pointer;
          // height: 20px;
          // padding: 10px 5px;
          // border-radius: 8px;

          margin: 0px 0;
          justify-content: space-between;
        }
        .span-not-selected {
          padding: 10px 5px;
          b {
            font-weight: 100 !important;
          }
        }
        .span-selected {
          background-color: #00aca8;
          padding: 10px 5px;
        }
      }
    }

    .circle-wrapper {
      height: 80vh !important;
    }
    .back-btn-wrapper {
      display: flex;
      justify-content: center;
      @media only screen and (max-width: 991px) {
        display: none;
      }
      .ant-btn {
        width: 50%;
        border-radius: 8px;
        background-color: #00aca8;
        color: #fff;
        height: 40px;
      }
    }
  }
}
