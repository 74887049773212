.confirm-circle-wrapper {
  //   display: flex;
  //   flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh !important;
  padding-top: 30px;
  //   justify-content: center;
  //   align-items: center;
  text-align: center;
}
.confirm-site-logo-wrapper-getdrawing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    color: #fff;
    margin: 0px;
    margin-left: 15px;
    font-size: 50px;
    margin-top: 20px;
  }

  @media (max-width: 407px) {
    h1 {
      font-size: 30px;
      margin-top: 20px;
    }
  }
}

.logo-login-getdrawing {
  width: 200px;
}

.getdrawing-current-txt {
  font-size: 16px;
  color: white;
  margin: 20px 0px 20px 0px;
}

.register-btn-getdrawing {
  width: max-content !important;
  background-color: #00aca8 !important;
  margin-bottom: 30px;
  border: 1px solid #00aca8 !important;
  color: #fff !important;
  padding: 0px 20px !important;
  margin: auto;

  border-radius: 10px !important;
  :hover {
    //   border: 1px solid #00aca8 !important;
    //   background-color: #00aca8 !important;
  }
  //   .ant-btn-lg {
  //     border-radius: 10px !important;
  //   }
}

.register-btn-getdrawing:hover,
.register-btn-getdrawing:active,
.register-btn-getdrawing:focus-visible {
  background-color: rgba(255, 255, 255, 0.863) !important;
  border-color: rgba(255, 255, 255, 0.863) !important;
  color: #000 !important;
  box-shadow: none;
}

.getdrawing-labels {
  text-align: center;
  width: 50%;
  margin: 0 auto 30px;
  @media (max-width: 407px) {
    width: auto;
  }
}
.label-wrapper-forgetpass {
  display: flex;
  justify-content: space-around;
}
