.new-cont2 {
  padding: 90px 15px 20px 15px;
  justify-content: center;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  align-items: center;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.mainTxtDiv2 {
  width: 600px;
  min-height: 90px !important;
  height: auto !important;
  background-color: rgba(198, 198, 198, 0.68);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 20px;
  padding: 5px;
  width: 80%;
  @media only screen and (max-width: 960px) {
    width: 80%;
    margin: 0 auto;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.main-choose-txt2 {
  color: black !important;
  font-size: 14px !important;
  font-weight: 400;
  margin: 0 auto;
  span {
    display: inline-block;
    // margin-left: 3px;
    font-weight: bold;
  }
  @media only screen and (max-width: 960px) {
    font-size: 14px;
    // margin: 20px;
    align-self: center;
    text-align: center;
    align-items: center;
  }
}

.new-form {
  width: 70%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.new-form p {
  text-align: center;
  color: #fff;
  font-size: 20px;
}
.new-form h3 {
  text-align: center;
  color: #fff;
  font-size: 30px;
}
.form-create2 {
  display: flex !important;
  align-items: center;
  justify-content: space-evenly !important;
  flex-direction: column !important;
  width: 100% !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  padding: 15px !important;
}
.create-input {
  border: 2px solid #00aca8 !important;
  border-radius: 10px !important;
  min-height: 40px !important;
  // background-color: ;
  background-color: #f2f2f2 !important;
  width: 100% !important;
}
.create-input:focus::placeholder {
  color: transparent !important;
}
.create-input::placeholder {
  color: #3e3f3a !important;
}
.form-side {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 15px;
  justify-content: flex-start;
  min-height: 100%;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}
.input-cont {
  display: flex;
  // justify-content: space-between;
  gap: 15px;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.create-btn {
  width: 30% !important;
  margin-top: 15px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  span {
    font-size: 12px;
  }
}
