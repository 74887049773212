@import '../../theme/_colors';
// $text-primary
.drawing-table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-right: 5px;
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
  .drawing-table-wrapper-tables {
    overflow-y: auto;
    height: 80vh;
    padding-bottom: 10px;
  }
  .drawing-table-wrapper-tables::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .drawing-table-wrapper-tables::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  .drawing-table-wrapper-tables::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
  h4 {
    color: $text-primary;
    text-align: center;
    margin: 0px;
    padding: 20px 20px 0 20px;
  }
  .drawing-by-wrapper {
    h5 {
      padding: 0 10px;
      margin: 20px 0;
      color: $text-plus-button;
    }
    .table-head-cell {
      color: $text-primary !important;
      // font-size: 15px !important;
      font-size: 12px !important;
      // font-weight: 600;
      // margin-right: 0px;
    }
    .ant-table-thead {
      tr {
        :first-child {
          width: 20%;
          @media only screen and (max-width: 800px) {
            width: 50%;
          }
        }
      }
      th {
        background-color: transparent;
      }
    }

    .ant-table-cell {
      padding: 0px;
      font-size: 12px;
      height: 0;

      span {
        width: 100% !important;
        padding: 0 5px;
        min-height: 40px;
        display: flex;
        align-items: center;
        height: 100%;
      }
      .cell-span-selected {
        background-color: #00aca8;
      }
    }
    // .ant-table-thead {
    //   thead {
    //     .table-head-cell {
    //       color: $text-primary !important;
    //       font-size: 15px !important;
    //     }
    //   }
    // }
  }
}
