.step5-main-div {
  width: 100%;
}
.step5-table-style {
  padding: 0 20px 30px;
  margin-top: 30px;
  height: 350px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
}

.step5-table-style {
  tr {
    border-bottom: none;
    &:first-child {
      border-bottom: 1px solid #707070 !important;
    }
  }
  td {
    border-bottom: 0px solid #707070 !important;
    border-left: 1px solid #707070 !important;
    text-align: center !important;
    &:first-child {
      border-left: none !important;
    }
    &:last-child {
      text-align: center !important;
    }
  }
  th {
    border-left: 1px solid #707070 !important;
    font-weight: bold;
    font-size: 12px;
    text-align: center !important;
    &:first-child {
      border-left: none !important;
    }
    &:last-child {
      text-align: left !important;
    }
  }
  .ant-table-tbody {
    tr {
      border-radius: 20px;
      td {
        padding: 0px !important;
      }
    }
  }
  .ant-table-wrapper {
    overflow: hidden;
  }
  .ant-table-tbody {
    overflow-y: scroll;
  }
  .ant-table-thead {
    position: sticky !important;
    top: 0px;
    background-color: white !important;
    z-index: 99;
    .ant-table-cell:nth-child(1) {
      width: 150px;
    }
    .ant-table-cell:nth-child(2) {
      width: 100px;
    }
    .ant-table-cell:nth-child(3) {
      width: 120px;
    }
    .ant-table-cell:nth-child(4) {
      width: 180px;
    }
    .ant-table-cell:nth-child(5) {
      width: 100px;
    }
    .ant-table-cell:nth-child(6) {
      width: 100px;
    }
    .ant-table-cell:nth-child(7) {
      width: 100px;
    }
    .ant-table-cell:nth-child(8) {
      width: 130px;
    }
    .ant-table-cell:nth-child(9) {
      width: 80px;
    }
  }
}

.cross-icon-step5 {
  // width: 24px;
  color: red;
  cursor: pointer;
}

.format-step5-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  p {
    font-size: 7px;
  }
}

.step5-item {
  font-size: 12px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: center;
  width: 105px;
  padding-top: 5px;
  padding-bottom: 15px;
}

.step5-status-div {
  // max-width: 100%;
  width: 107px;
  height: 24px;
  border-radius: 20px;
  justify-items: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;

  padding: 10px;
  border-radius: 25px;
  border: 1px solid #00aca8;
}

.txt-step5-status {
  font-size: 8px;
  color: black;
  width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
