.sidebar-cont {
  background: #fff;
  width: 22%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 80vh;
  @media only screen and (max-width: 960px) {
    width: 100%;
    flex-direction: row;
  }
}
.sidebar-cont h5 {
  color: #404041;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

hr {
  background-color: #4ca9a7;
  width: 100%;
  border: none;
  opacity: 1;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}

.sidebar-options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    width: 50%;
    flex-direction: column;
    justify-content: center;
    border: none;
  }
}
.sidebar-options p {
  margin: 0;
  font-size: 14px;
}
.sidebar-logo {
  width: 30px;
}
.invites-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  overflow-y: scroll;
  @media only screen and (max-width: 960px) {
    display: none;
  }
}
.invites-cont::-webkit-scrollbar {
  display: none;
}
.invites-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #9b9b9b;
  margin-bottom: 10px;
}
.invites-info {
  font-size: 12px;
}
.invites-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.invites-btn-accept {
  background-color: #4ca9a7 !important;
  border-radius: 10px !important;
  color: #fff !important;
  width: 50% !important;
  border-color: transparent !important;
}
.invites-btn-reject {
  background-color: #697689 !important;
  border-radius: 10px !important;
  color: #fff !important;
  width: 50% !important;
  border-color: transparent !important;
}

.new-project-div {
  flex-direction: row;
  display: flex;
}
.new-project-crowIcon {
  width: 20.5px;
  height: 16px;
  display: flex;
  margin-right: 5px;
}
