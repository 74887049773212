.column-style {
  background-color: red;
}
.step3-drawing-table {
  margin: 0 auto;
  align-self: center;
  width: 70%;
  margin-top: 20px;
  background-color: #fff;
  overflow-y: scroll;
  height: 250px !important;
}
.Drawings-information-txt {
  margin: 0 auto;
  width: 70%;
  text-align: center;
  font-size: 16px;
}

.step3-drawing-table {
  .ant-table-thead {
    position: sticky !important;
    top: 0px;
    background-color: white !important;
    z-index: 99;
  }
  .ant-table-thead {
    .ant-table-cell:nth-child(1) {
      width: 100px;
    }
    .ant-table-cell:nth-child(2) {
      width: 100px;
    }
    .ant-table-cell:nth-child(3) {
      width: 100px;
    }
    .ant-table-cell:nth-child(4) {
      width: 100px;
    }
    .ant-table-cell:nth-child(5) {
      width: 100px;
    }
    .ant-table-cell:nth-child(6) {
      width: 100px;
    }
  }
}

.step3-dropdown-div {
  display: flex;
  width: 120px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 25px;
  border: 2px solid #00aca8;
  display: flex;
  cursor: pointer;
}
.step3-dropdown-div-disable {
  display: flex;
  width: 120px;
  padding: 5px;
  margin-top: 20px;
  border-radius: 25px;
  border: 2px solid #f0f0f0;
  display: flex;
}

.step3-dropdrown {
  min-width: 80px;
  left: 498px;
  top: 167px;
}

.step3-menu-item {
  width: 120px;
  height: 150px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin-left: 50px;
  overflow-y: scroll;
  @media only screen and (max-width: 800px) {
    display: none;
  }
}

.step3-item {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  //border-width: 2px;
  // border-color: #000000;
  .ant-dropdown-menu-title-content {
    flex-direction: row;
    display: flex;
    p {
      flex: 1;
      height: 10px;
      align-self: end;
    }
    // .delimiter-circle-item-show {
    //   border-radius: 20px;
    //   background-color: #00aca8;
    //   width: 30px;
    //   height: 30px;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   align-content: center;
    //   p {
    //     align-self: center;
    //     color: #fff;
    //     height: 100%;
    //     font-size: 6px;
    //     width: 100%;
    //     text-align: center;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin: 0;
    //   }
    // }
  }
}

.step3-dropdown-txt {
  flex: 1;
  height: 7px;
  width: 80px;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cleaning-option-naming-div {
  display: flex;
  flex-direction: row;
  width: 70%;
  align-items: center;
  justify-content: space-around;
  align-self: center;
  margin: 0 auto;
}

.step3-column-title {
  color: black;
}
.step3-column-title-disable {
  color: #f0f0f0;
}

.cleaning-option-name-main-div {
  width: 200;
  align-items: center;
  display: flex;
  justify-items: center;
  justify-content: center;
}
.step3-column-item-div {
  // width: 120px;
  height: 50px;
  align-items: center;
  display: flex;
  margin: auto;
  width: 120px;
  justify-content: center;
  justify-items: center;

  p {
    color: #000000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: auto;
    flex: auto;
    text-align: center;
  }
}

.step3-column-item-div-disable {
  width: 120px;
  p {
    color: #f0f0f0;
  }
}

.step3-drawing-table {
  tr {
    border-bottom: none;
    &:first-child {
      border-bottom: 1px solid #707070 !important;
    }
  }
  td {
    border-bottom: 0px solid #707070 !important;
    border-left: 1px solid #707070 !important;

    text-align: center !important;
    &:first-child {
      border-left: none !important;
    }
    &:last-child {
      text-align: center !important;
    }
  }
  th {
    border-left: 1px solid #707070 !important;

    text-align: center !important;
    &:first-child {
      border-left: none !important;
    }
    &:last-child {
      text-align: center !important;
    }
  }
  .ant-table-tbody {
    tr {
      border-radius: 20px;
      td {
        padding: 0px !important;
      }
    }
  }
}

.step3-table-row-drawings {
  td {
    p {
      // margin-top: 5px;
      // margin-bottom: 5px;
      // height: 100%;
      // background-color: #fff;
      // min-height: 20px;
      // display: flex;
      // align-items: center;
      // // padding: 5px 5px;
      // align-items: center;
      // justify-content: center;
    }
  }
}
