.profile {
  padding: 90px 15px 20px 15px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .info-cont {
    display: flex;
    width: 100%;
    gap: 5px;
    padding: 16px;
    border-radius: 10px;
  }
  .info-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-info {
    width: 100%;
    max-width: 700px;
    background-color: #fff;
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    // padding: 12px 6px;
    border-radius: 4px;

    &-border {
      border: 1px solid #dfd7ca;
      border-radius: 4px;

      .border-line {
        height: 1px;
        background-color: #dfd7ca;
      }
    }

    p {
      color: #000;
      margin: 0%;
      text-align: left;
      font-size: 14px;
      font-weight: normal;
    }
  }
}

.border-line-mo {
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    height: 1px;
    background-color: #dfd7ca;
    width: 360px;
  }
}
.creite-item {
  display: flex;
  flex-direction: row;
  width: 280px;
  margin-top: 5px;
  justify-content: space-between;
}

.info-cont-credit {
  display: flex;
  width: 100%;
  gap: 5px;
  padding: 16px;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.bold-txt {
  font-weight: bold !important;
  font-size: 16px !important;
}

.primuim-credit-btn {
  display: flex;
  width: 216px;
  height: 50px;
  background-color: #00aca8;
  border-radius: 30px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    font-size: 12px !important;
    color: white !important;
  }
  @media only screen and (max-width: 800px) {
  }
}

.credite-div {
  flex-direction: column;

  align-self: flex-start;
}
