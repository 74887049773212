.new-form-upload {
  width: 100%;
  padding: 0 10px 5px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  @media only screen and (max-width: 960px) {
    width: 100%;
  }
  @media only screen and (max-width: 780px) {
    margin-top: 50px;
    padding: 0 20px 5px 20px;
  }

  .rev-upload-head {
    color: #fff;
    font-size: 30px;
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
  .rev-upload-para {
    font-size: 20px;
    color: #fff;
    @media only screen and (max-width: 780px) {
      display: none;
    }
  }
  .form-upload-rev {
    width: 50%;
    display: flex;

    flex-direction: column;

    border-radius: 10px;
    align-items: center;
    background-color: #fff;
    @media only screen and (max-width: 1140px) {
      width: 70%;
    }
    @media only screen and (max-width: 780px) {
      width: 100%;
    }
  }
  .upload-main-div {
    height: 110px;
    width: 85px;
  }
  .upload-file-wrapper {
    display: flex;
    margin: 20px 0px 50px;
    align-content: center;
    align-self: center;

    flex-direction: column;
    p {
      font-size: 12px;
    }
    .upload-btn {
      position: relative;

      height: 50;
      display: flex !important;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      margin: 0px 30px 0px 0px;
      width: 85px;
      padding: 0px 0px;
      height: 80px;
      border: 2px solid #00aca8;
      border-radius: 15px;
      .img_upload {
        width: 40px;
        height: 50px;
      }
      .ant-upload-list-text-container {
        position: absolute;
        left: 0;
        margin-top: 8px;
        width: 150px;
      }

      // .ant-btn-default {
      //   display: flex;
      //   align-items: center;
      //   width: 100px;
      //   padding: 0px 0px;
      //   height: 80px;
      //   border: 2px solid gray;
      //   border-radius: 8px;
      // }
      // .ant-btn-default :first-child {
      //   display: none;
      // }
      // .ant-btn-default :last-child {
      //   font-size: 20px;
      //   color: gray;
      //   font-weight: bold;
      //   margin: 0px 5px;
      //   align-items: center;
      //   align-self: center;
      // }
    }
  }
}
.revision-input-cont {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;

  .form-side-rev {
    width: 85% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .input-rev-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
      @media only screen and (max-width: 526px) {
        width: 80%;
      }
    }
    .info-rev {
      color: #404041;
      font-size: 24px;
      margin: 20px 0 10px;
      text-align: center !important;
      @media only screen and (max-width: 780px) {
        font-size: 14px;
      }
    }
    .rev-label {
      margin: 20px 0 10px;
      text-align: center;
      font-size: 12px;
      color: black;
      font-weight: bold;
    }

    .rev-create-input {
      text-align: left !important;
      height: 50px !important;
      border-radius: 25px !important;
      border: 2px #00aca8 solid;
    }
    .rev-create-input::placeholder {
      color: gray;
      text-align: left;
    }
    .rev-create-input-notes {
      border-radius: 25px !important;
      height: 50px;
      text-align: left !important;
      border: 2px #00aca8 solid;
    }
    .rev-create-input-notes::placeholder {
      text-align: left;
      color: gray;
    }
  }
}

.revision-cont {
  width: 50%;
}

.info {
  font-size: 20px !important;
  // color: #000 !important;
  font-weight: 500 !important;
  margin: 2px;
  width: 100%;
  text-align: left !important;
}
.center {
  width: 65%;
  @media only screen and (max-width: 960px) {
    width: 100% !important;
  }
}

.upload-rev-btn {
  background-color: #00aca8 !important;
  border: none !important;
  color: #fff !important;

  text-align: center !important;
  text-decoration: none !important;
  display: inline-block !important;
  font-size: 16px !important;
  border-radius: 25px !important;
  margin: 10px 0;
  width: 200px;

  span {
    font-size: 14px;
    margin: 0 auto;
  }
}

.selected-status-div {
  max-width: 100%;
  width: 350px;
  height: 50px;
  border-radius: 20px;
  justify-items: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;

  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #00aca8;
}
.selected-status-div-disable {
  max-width: 100%;
  width: 350px;
  height: 50px;
  border-radius: 20px;
  justify-items: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  background-color: #f5f5f5;
  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
  border: 2px solid #00aca8;
}
.ant-dropdown-options {
  display: flex;
  flex-direction: row;
  display: flex;
  justify-items: center;
  align-items: center;

  width: 100%;
}
.ant-dropdown-options :hover {
  color: black;
}
.options-text {
  width: 85%;
  color: black;
}
.options-text-placeholder {
  width: 85%;
  color: gray;
}
