.access-history-item {
  p {
    color: #3e3f3a;
    font-size: 16px;
    margin: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.access-history-cont {
  width: 80% !important;
  align-self: center;
}
