.archived-comments {
  padding: 110px 15px 20px 15px !important;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  justify-content: center;
  align-items: flex-start;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 700px) {
    padding: 30px 5px 5px 5px !important;
  }
  .comment-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .comment-input-area {
    // background-color: #000;
    padding: 10;
    height: 15vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .msg-label {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #00aca8;
      @media only screen and (max-width: 700px) {
        padding-top: 10px;
        border-top: 1px solid #00aca8;
        border-bottom: 0px;
        width: 100%;
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .comment-header {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .comment-header h5 {
    font-size: 22px;
    font-weight: 500;
    color: #404041;
  }
  .table-cont-comment {
    overflow-y: auto;
    width: 100%;
    padding-top: 50px;
    min-height: 100px;
    color: #3e3f3a;
    // max-height: 300px;
  }
  .loader-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .table-cont-comment::-webkit-scrollbar {
    display: none;
  }
  .comment-info {
    // display: flex;
  }
  .input-area-header {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-top: 1px solid #4ca9a7;
    margin-top: 10px;
  }
  .input-area-header h5 {
    font-size: 19px;
  }
  .input-cont {
    display: flex;
    width: 90%;
    align-items: center;
    .input-cont-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .send-input-wrapper {
        width: 60%;
        // display: flex !important;
        // width: 100% !important;
        @media only screen and (max-width: 700px) {
          width: 80%;
        }
      }
      .send-msg-input {
        width: 100%;
        border: 2px solid #00aca8;
        border-radius: 20px;
        @media only screen and (max-width: 700px) {
          height: 30px;
        }
      }
    }
  }
  .comment-btn {
    background-color: #00aca8 !important;
    color: #fff !important;
    border-radius: 8px !important;
    min-height: 40px !important;
    font-size: 16px !important;
    width: 180px;
    // padding: 0 2px;
    // width: 40px !important;
    // display: flex !important;
    // align-items: center;
    // justify-content: center;
    @media only screen and (max-width: 700px) {
      display: none;
    }
  }
  .comment-btn-plus {
    background-color: #00aca8 !important;
    color: #fff !important;
    border-radius: 20px !important;
    min-height: 30px !important;
    font-size: 16px !important;
    font-weight: bold;
    width: 30px;
    @media only screen and (min-width: 700px) {
      display: none;
    }
  }
  .comment-btn span {
    font-family: Arial, Helvetica, sans-serif !important;
    // font-size: 16px;
    // font-weight: 600;
  }
  .comment-message {
    font-style: italic;
    margin-left: 20px;
  }
  .comment-date {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .comment-cont {
    width: 65%;
    height: 85vh !important;
    display: flex;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between !important;
    @media only screen and (max-width: 960px) {
      width: 100%;
    }
  }
}
