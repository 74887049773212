.main-div-drawings-error {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-top: 10px;
}
.drawings-error-heading {
  font-size: 27px;
  color: black;
  font-weight: bold;
  margin-top: 10px;
}

.drawings-error-discription {
  font-size: 16px;
  color: black;
  span {
    color: black;
    font-weight: bold;
  }
}

.table-style-error {
  display: flex;
  margin: 0 auto;
  align-self: center;
  width: 700px;
  height: 400px;
  margin-top: 20px;
  background-color: #fff;
}

.table-style-error {
  tr {
    border-bottom: none;
    &:first-child {
      border-bottom: 1px solid #707070 !important;
      text-align: left !important;
    }
  }
  td {
    border-bottom: 0px solid #707070 !important;
    border-left: 1px solid #707070 !important;

    text-align: left !important;
    &:first-child {
      border-left: none !important;
      text-align: left !important;
    }
    &:last-child {
      text-align: left !important;
    }
  }
  th {
    border-left: 1px solid #707070 !important;

    text-align: left !important;
    &:first-child {
      border-left: none !important;
      text-align: left !important;
    }
    &:last-child {
      text-align: left !important;
    }
  }
  .ant-table-tbody {
    tr {
      border-radius: 20px;
      text-align: left !important;
      td {
        padding: 0px !important;
        text-align: left !important;
      }
    }
  }
  .ant-table-thead {
    position: sticky !important;
    top: 0px;

    z-index: 99;
    .ant-table-cell:nth-child(1) {
      width: 200px;
    }
  }
}

.drawings-error-table-row-txt {
  font-size: 12px;
  width: auto;
  height: auto;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: left;
  margin-left: 10px;
}
.stepDrawing-text-item-div-error {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.12);
  min-height: 55px;
  display: flex;
  // align-items: center;
  // padding: 5px 15px;
  // align-items: center;
  // justify-content: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: left;
}

.error-text-item-div {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 100%;
  background-color: rgba(255, 0, 0, 0.12);
  min-height: 55px;
  display: flex;
  // align-items: center;
  // padding: 5px 15px;
  // align-items: center;
  // justify-content: scenter;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: left;
}
.table-row-error-drawings {
  td {
    // p {

    // }
    &:first-child p {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:last-child p {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  //   .ant-table-cell:first-child() {
  //     background-color: rgba(255, 0, 0, 0.12) !important;
  //     border-radius: 20px !important;
  //   }

  .ant-table-thead {
    .ant-table-cell:nth-child(1) {
      width: 200px;
    }
    .ant-table-cell:nth-child(2) {
      width: 500px;
    }
  }
}

.ant-table-row-level-0 {
  border-radius: 20px !important;
}
