.confirm-circle-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh !important;
  padding-top: 30px;
}
.confirm-site-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: #fff;
    margin: 0px;
    margin-left: 15px;
    font-size: 50px;
  }

  img {
    width: 90px;
  }

  @media (max-width: 407px) {
    h1 {
      font-size: 30px;
    }

    img {
      width: 50px;
    }
  }
}
.confirm-site-cross-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  h6 {
    color: #fff;
    margin: 0px;
    font-size: 1.5em;
    margin-left: 15px;
    // margin-bottom: 30px;
  }
  img {
    width: 40px;
  }
}
.confirm-site-arrowdown-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  h6 {
    color: #fff;
    margin: 0px;
    font-size: 20px;
    margin-left: 15px;
    margin-bottom: 30px;
  }
  .register-btn {
    width: max-content !important;
    background-color: #00aca8 !important;
    margin-bottom: 30px;
    border: 1px solid #00aca8 !important;
    color: #fff !important;
    padding: 0px 20px !important;
    :hover {
      //   border: 1px solid #00aca8 !important;
      //   background-color: #00aca8 !important;
    }
  }

  img {
    width: 50px;
    margin-bottom: 30px;
  }
}

.confirm-site-iframe-logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  h6 {
    color: #fff;
    margin: 0px;
    font-size: 50px;
    font-weight: lighter;
    margin-left: 15px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      font-size: 30px;
    }
  }
  .video-iframe {
    width: 512px;
    // height: 128px;
    @media (max-width: 600px) {
      width: 99%;
    }
  }
}
