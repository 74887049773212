.share-perissions-div {
  display: flex;
  flex-direction: row;
  width: 800px;
  margin: 0 auto;
  // height: 100%;

  // overflow: auto;

  // @media only screen and (max-width: 500px) {
  //   width: 100%;
  //   height: 350px;
  //   // overflow: hidden;
  //   // overflow-y: scroll;
  // }
}
.step6-div-tables {
  height: 400px;
  overflow-y: auto !important;
  overflow: hidden;
}
.step6-first-table-style {
  width: 350px;
  // height: 350px;
  .ant-table-cell {
    height: 70.2px;
  }
  .ant-table-cell {
    height: 64.5px;
    p {
      margin: 0 auto !important;
    }
  }
  @media only screen and (max-width: 500px) {
    width: auto;
    // overflow: hidden !important;
    // height: auto;
  }
  .ant-table-wrapper {
    overflow-x: unset;
  }
  .ant-table-thead {
    position: sticky !important;
    top: 0px;
    z-index: 99;
    tr {
      th {
        text-align: center;
      }
    }
  }
}

.select-firsrt-column-row {
  border-radius: 20px;
  width: 55px;
  height: 17px;
  background-color: #00aca8;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  @media only screen and (max-width: 960px) {
    // overflow-x: hidden;
  }
}
.select-second-column-row {
  border-radius: 20px;
  width: 90px;
  height: 21px;
  background-color: #00aca8;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media only screen and (max-width: 960px) {
    // overflow-x: hidden;
  }
}

.select-first-column-one-txt {
  color: white;
  font-size: 8px;
}
.select-first-column-two-txt {
  color: white;
  font-size: 5px;
  margin: auto;
}
.select-second-column-two-txt {
  color: white;
  font-size: 8px;
  margin: auto;
  font-weight: 400;
}

.step6-second-table-style {
  width: 60%;
  // height: 3 50px;
  overflow: hidden;
  overflow-x: auto;
  .ant-table-cell {
    height: 70px;
  }
  .ant-table-cell {
    height: 64px;
  }

  @media only screen and (max-width: 500px) {
    width: auto;
    // overflow: hidden !important;
    // overflow-x: visible !important;
    // height: fit-content;
  }
  .ant-table-wrapper {
    overflow-x: unset;
  }
}

.column-checkbob-div {
  justify-content: center;
  display: flex;
}

.columns-checkBox-style {
  width: 20px;
  height: 20px;
  align-self: flex-end;
  margin-right: 5px;
}

.step6-columnsTwo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100;
}

.step6-permission-table-empty {
  margin: auto;
  margin-top: 100px;
  p {
    font-size: 18px;
    font-weight: 400;
  }
}

.step6-div-uploading {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.step6-main-div {
  height: 350px;
  max-height: 350px;
}

.step6-discheckBox {
  border: 2px solid #d8d8d8;
  width: 22px;
  height: 20px;
  display: flex;
  justify-content: center;
  /* padding: 2px; */
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  img {
    width: 12px !important;
    height: 11px !important;
  }
}

.step6-table-column-one {
  font-size: 12px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: center;
  width: 100px;
}

.step6-table-one-item {
  width: 80px;
  font-size: 12px;
  text-align: center;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: auto;
  flex: auto;
  text-align: center;
}

.step6-second-table-style {
  .ant-table-thead {
    position: sticky !important;
    top: 0px;
    z-index: 99;
    .ant-table-cell {
      width: 100px;
    }
  }
}

.step6-share-title-circle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  // margin: auto;
  width: 100px;
  text-align: center;
}

.step6-share-title-circle-admin {
  color: #d8d8d8;
}

.step6-select-all {
  background-color: #00aca8;
  width: 100px;
  height: 34px;
  border-radius: 20px;
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
  p {
    color: white;
    font-size: 12px;
    margin: auto;
  }
}

.step6-first-table-title {
  font-size: 12px;
}
