.step7-main-div {
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 960px) {
    margin: 0 auto;
  }
}
.upload-click-div {
  background-color: #00aca8;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.uploaded-txt {
  font-size: 27px;
  font-weight: 700;
  margin-top: 20px;
}

.step7-loading-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
