.auth-box {
  margin-top: 30px;
}
.login-container {
  width: 100%;
  min-height: 100vh;
}
.login-form {
  width: 40%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginWrapper {
  height: 100vh;
}
.loginLogoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-button-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.login-button-wrapper-forget {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.login-button-wrapper div {
  width: 12%;
  margin: 0 10px;
}
@media (max-width: 1604px) {
  .login-button-wrapper div {
    width: 20% !important;
  }
}
@media (max-width: 532px) {
  .login-button-wrapper div {
    width: 30% !important;
  }
}
.logo-login {
  width: 100%;
  height: auto;
  max-width: 273px !important;
}
.label-wrapper {
  text-align: center;
}
.title-cont {
  display: flex;
}
.title-cont p {
  font-size: 50px;
  color: #fff;
  font-family: 'Poppins' !important;
  margin: 0;
}
.tagline {
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.login-input {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4ca9a7 !important;
  color: #fff !important;
  text-align: center;
  width: 100% !important;
  padding-bottom: 10px !important;
  border-radius: 0;
  margin-bottom: 5px !important;
}
.login-input :placeholder-shown {
  color: #404041 !important;
  text-align: center !important;
}
.login-input:focus::placeholder {
  color: #404041 !important;
  color: transparent !important;
}
.login-input:-webkit-autofill,
.login-input:-webkit-autofill:hover,
.login-input:-webkit-autofill:focus,
.login-input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
  // background-color: transparent !important;
}
.reg-input:-webkit-autofill,
.reg-input:-webkit-autofill:hover,
.reg-input:-webkit-autofill:focus,
.reg-input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: #000 !important;
  // background-color: transparent !important;
}
.form-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px 10px 30px;
  gap: 10px;
}

.form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px 10px 30px;
  gap: 10px;
  .ant-form-item {
    width: 40%;
  }
  .ant-form-item-explain-error {
    text-align: center;
  }
}
.form-signup {
  .ant-row {
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin: 10px 0;
    // .ant-col {

    // }
  }
}
.register-form-wrapper {
  margin-top: 50px;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  .ant-input {
    ::placeholder {
      color: #000;
    }
  }
  .ant-form-item-explain-error {
    text-align: center;
  }
}
@media (max-width: 576px) {
  .register-form-wrapper {
    margin: 50px 10px 0 10px;
  }
}
@media (max-width: 1300px) {
  .form-login {
    .ant-form-item {
      width: 60%;
    }
  }
}
.btn-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  div {
    margin: 0 10px;
    width: 20% !important;
  }
}
@media (max-width: 1800px) {
  .btn-cont {
    div {
      width: 35% !important;
    }
  }
}

@media (max-width: 458px) {
  .btn-cont {
    div {
      width: 45% !important;
    }
  }
}

.signup-btn {
  width: 100% !important;
  // overflow: unset !important;

  background-color: #4ca9a7 !important;
  color: #fff !important;
  border-color: #4ca9a7 !important;
  border-radius: 10px !important;
  span {
    width: 100px;
  }
}

.back-btn {
  width: 100% !important;
  background-color: #404041 !important;
  border-color: #404041 !important;
  // padding: 10px !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.login-btn {
  width: 100% !important;
  // overflow: unset !important;

  background-color: #4ca9a7 !important;
  color: #fff !important;
  padding: 0px 30px !important;
  border-color: #4ca9a7 !important;
  border-radius: 10px !important;
}
.register-btn {
  width: 100% !important;
  // overflow: unset !important;
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 0 10px !important;
  color: #000 !important;
  border-radius: 10px !important;
}
.verification-btn {
  width: max-content !important;
  // overflow: unset !important;
  background-color: #fff !important;
  border-color: #fff !important;
  padding: 0 10px !important;
  color: #000 !important;
  border-radius: 10px !important;
}
.btn-cont-send-btn {
  width: max-content !important;
}
// @media (min-width: 1610px) {
//   .btn-cont-send-btn {
//     width: 40% !important;
//   }
// }
// @media (max-width: 1610px) {
//   .btn-cont-send-btn {
//     width: 70% !important;
//   }
// }
.send-pass-btn {
  width: 250px !important;
  background-color: #fff !important;
  border-color: #4ca9a7 !important;
  // padding: 10px !important;
  color: #4ca9a7 !important;
  border-radius: 10px !important;
}
.send-pass-btn:hover,
.send-pass-btn:active,
.send-pass-btn:focus-visible {
  background-color: rgba(255, 255, 255, 0.863) !important;
  border-color: #68bab9 !important;
  color: #4ca9a7 !important;
  box-shadow: none;
}

.back-btn:hover,
.back-btn:active,
.back-btn:focus-visible {
  background-color: #585858 !important;
  border-color: #585858 !important;
  color: #fff !important;
  box-shadow: none;
}
.login-btn:hover,
.login-btn:active,
.login-btn:focus-visible {
  background-color: #68bab9 !important;
  border-color: #68bab9 !important;
  box-shadow: none;
}
.register-btn:hover,
.register-btn:active,
.register-btn:focus-visible {
  background-color: rgba(255, 255, 255, 0.863) !important;
  border-color: rgba(255, 255, 255, 0.863) !important;
  color: #000 !important;
  box-shadow: none;
}
.verification-btn:hover,
.verification-btn:active,
.verification-btn:focus-visible {
  background-color: rgba(255, 255, 255, 0.863) !important;
  border-color: rgba(255, 255, 255, 0.863) !important;
  color: #000 !important;
  box-shadow: none;
}
.frgt-pass-labels {
  color: #fff;
  // align-self: flex-start;
  font-size: 13px;
  text-align: center;
  margin: 0 5px;
}
.label-wrapper-forgetpass {
  text-align: center;
}
.frgt-pass-labels:hover {
  text-decoration: underline;
  color: #fff;
}
.frgt-pass {
  color: #fff;
  // align-self: flex-start;
  font-size: 13px;
  text-align: center;
}
.frgt-pass:hover {
  text-decoration: underline;
  color: #fff;
}
.forgot-cont {
  width: 42%;
}
.frgt-form {
  width: 60%;
}
.abt-frgt-pass {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
}
.register-form {
  background-color: #fff;
  border-radius: 15px;
}
.reg-input {
  color: #000 !important;
}
@media (max-width: 960px) {
  .login-form {
    width: 100% !important;
  }
  // .logo-login {
  //   width: 263px;
  // }
  .frgt-form {
    width: 100%;
  }
  .abt-frgt-pass {
    flex-direction: column;
    gap: 10px;
  }
  .tagline {
    text-align: center;
  }
}
// register css
.register-label-wrapper {
  margin-top: 10px;
  h3 {
    font-weight: bold;
  }
  h3,
  h4 {
    text-align: center;
    color: #fff !important;
  }
}
