.dashboard {
  padding: 110px 15px 20px 15px !important;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  width: 100vw;
  max-width: 1560px;

  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
