.customBreadcrumb {
  color: #00aca8 !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
}

.customBreadcrumbMobView {
  color: #00aca8 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  padding: 5px;
}

.breadCrumbWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.breadCrumbMobView {
  display: none;
}

.breadCrumbWebView {
  display: block;
}

@media (max-width: 780px) {
  .breadCrumbWebView {
    display: none;
  }

  .breadCrumbMobView {
    display: flex;

    img {
      margin-right: 8px;
    }

    .ant-popover {
      margin-left: 5px !important;
    }
  }
}
