.details {
  padding: 110px 15px 20px 15px;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
}
.details-drawing {
  padding: 110px 15px 20px 15px;
  display: flex;
  gap: 15px;
  min-height: 100vh;
  // margin-top: 80px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    padding: 75px 15px 15px 15px;
  }
}
